<script>
  import { fade } from "svelte/transition";

  export let query;

  let amountOfDummyProducts = 2;

  if(window.innerWidth>=1920) {
    amountOfDummyProducts = 4;
  } else if (window.innerWidth>=1440) {
    amountOfDummyProducts = 3;
  }

</script>

<style>
  .products {
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }

  .product {
    max-width: 400px;
    width: 100%;
  }

  @media screen and (min-width:768px) {
    .product {
      width: 50%;
    }
  }

  @media screen and (min-width:1024px) {
    .product {
      max-width: none;
    }
  }

  @media screen and (min-width:1440px) {
    .product {
      width: calc( 100% / 3 );
    }
  }

  @media screen and (min-width:1920px) {
    .product {
      width: 25%;
    }
  }

  .product a {
    display: flex;
    border-radius: 7px;
    padding: 0.5rem;
    margin: 0.5rem 0;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  }

  @media screen and (min-width: 600px) {
    .product a {
      margin: 0.5rem;
    }
  }

  .product a h1 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  .product .img-container {
    width: 30%;
    min-width: 30%;
    min-height: 40px;
    margin-right: 0.5rem;
  }

  .product img {
    width: 100%;
  }

  :global(.products.component .product del),
  :global(.products.component .product ins),
  :global(.products.component .product .installments) {
    color: black;
  }

  :global(.products.component .product del) {
    color: gray;
  }

  :global(.products.component .product .installments .installment-amount),
  :global(.products.component .product .installments .amount) {
    font-weight: bold;
  }

  :global(.products.component .product .no-interest) {
    margin-left: 0.25rem;
    background: #2279d3;
    color: white;
    text-transform: uppercase;
    font-size: 0.7rem;
    padding: 0.125rem 0.25rem;
    border-radius: 3.5px;
  }

  .placeholder {
    animation: blur 1s infinite alternate ease-in-out;
    pointer-events: none;
  }

  @keyframes blur {
    0% {
      filter: blur(5px);
    }

    100% {
      filter: blur(7px);
    }
  }

  .loader {
    width: 30px;
    height: auto;
    margin: auto;
    display: block;
    margin-top: 2rem;
  }

  .not-found {
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.75rem;
  }
</style>

{#if query}
  {#await query}
      <ul class="products component placeholder">
      {#each Array(amountOfDummyProducts) as _}
          <li class="product">
            <a href="/">
              <section class="img-container">
                <img
                  src="/wp-content/uploads/2019/11/Hp-omen-portada-Pagina.jpg"
                  alt />
              </section>
              <section class="info">
                <h1>Hp Omen 15 DC1088wm</h1>
                <del>
                  <span class="woocommerce-Price-amount amount">
                    <span class="woocommerce-Price-currencySymbol">$</span>
                    156,000
                  </span>
                </del>
                <ins>
                  <span class="woocommerce-Price-amount amount">
                    <span class="woocommerce-Price-currencySymbol">$</span>
                    134,999
                  </span>
                </ins>
                <section class="installments">
                  <span class="installment-amount">18</span>
                  x
                  <span class="woocommerce-Price-amount amount">
                    <span class="woocommerce-Price-currencySymbol">$</span>
                    7,500
                  </span>
                  <span class="no-interest">Sin interés</span>
                </section>
              </section>
            </a>
          </li>
      {/each}
      </ul>
    <img
      class="loader"
      alt="Cargando.."
      src="/wp-content/uploads/2020/02/loader.svg" />
  {:then products}
    {#if products.length}
      <ul class="products component">
        {#each products as product}
          <li class="product" in:fade>
            <a href={product.permalink}>
              <section class="img-container">
                <img src={product.thumbnail} alt="" />
              </section>
              <section class="info">
                <h1>{product.post_title}</h1>
                {@html product.formatted_price}
              </section>
            </a>
          </li>
        {/each}
      </ul>
    {:else}
      <p class="not-found">No se encontró ningún producto con esas características.</p>
    {/if}
  {:catch error}
    {error.message}
  {/await}
{/if}

<script>
  import { fade } from "svelte/transition";

  import { createEventDispatcher } from "svelte";

  export let title, icon;

  document.body.classList.add("popup");

  const dispatch = createEventDispatcher();
  const dispatchClose = () => {
    document.body.classList.remove("popup");
    dispatch("close");
  };
</script>

<style>
  .popup {
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  .content {
    position: relative;
    width: 90%;
    height: 90%;
    background: white;
    border-radius: 7px;
    padding: 1rem;
    overflow-y: auto;
  }

  .title {
    font-size: 1.25rem;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .icon {
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
  }

  @media screen and (min-width: 1440px) {
    .content {
      padding: 2rem;
    }
    .title {
      font-size: 2rem;
    }
    .icon {
      width: 5rem;
      height: 5rem;
    }
  }

  .close {
    position: fixed;
    top: 0;
    right: 0;
    background: #2279d3;
    color: white;
    border-radius: 0 0 0 50%;
    width: 2.25rem;
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    border: 0;
    line-height: 1rem;
  }

  :global(body.popup) {
    overflow: hidden;
  }
</style>

<section class="popup" transition:fade on:click|self={dispatchClose}>
  <section class="content">
    <h1 class="title">
      <img src={icon} alt class="icon" />
      {title}
    </h1>
    <slot />
    <button class="close" on:click={dispatchClose}>
      <i class="fas fa-times" />
    </button>
  </section>
</section>

<script>
  import { createEventDispatcher } from "svelte";

  export let name, id, img;

  const dispatch = createEventDispatcher();
  const dispatchClick = e => {
    dispatch("click", { name, id, img });
  };
</script>

<style>
  .category {
    cursor: pointer;
    min-width: 8.75rem;
  }
  .img {
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.2));
  }

  .name {
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
  }

  @media screen and (min-width:1024px) {
    .category {
      flex: 1;
    }
    .img {
      width:100%;
    }
    .name {
      font-size: 1rem;
    }
  }
</style>

<li class="category" on:click={dispatchClick}>
  <img class="img" src={img} title={name} />
  <h1 class="name">{name}</h1>
</li>

<script>
  import { fly } from "svelte/transition";
  import { createEventDispatcher } from "svelte";

  let attributes = [
    {
      name: "Pantalla",
      id: "pa_pantalla",
      img: "/wp-content/uploads/2020/02/Pantalla.png",
      terms: [
        { name: '11"', id: "286" },
        { name: '13"', id: "79" },
        { name: '14"', id: "143" },
        { name: '15.6"', id: "58" },
        { name: '17.3"', id: "222" }
      ]
    },
    {
      name: "RAM",
      id: "pa_memoria-ram",
      img: "/wp-content/uploads/2020/02/Memoriaram.png",
      terms: [
        { name: "2Gb", id: "170" },
        { name: "4 Gb", id: "226" },
        { name: "8 Gb", id: "27" },
        { name: "12 Gb", id: "163" },
        { name: "16 Gb", id: "77" },
        /*{ name: "20Gb – 4Gb + 16Gb Optane", id: "55" },
        { name: "20Gb- 4Gb + 16Gb Intel Optane", id: "215" },
        { name: "24Gb – 8Gb + 16Gb Intel Optane", id: "191" },
        { name: "24Gb- 8Gb + 16Gb Optane", id: "202" },
        { name: "28Gb – 12Gb + 16 Optane", id: "298" },
        { name: "32Gb – 16Gb + 16Gb Optane", id: "276" }*/
      ]
    },
    {
      name: "Disco",
      id: "pa_tipo-de-disco",
      img: "/wp-content/uploads/2020/02/Tipodedisco.png",
      terms: [
        { name: "HDD", id: "89" },
        { name: "SSD", id: "30" },
        { name: "SSHD", id: "272" },
        { name: "Optane", id: "56" },
        { name: "Mecanico", id: "231" },
        { name: "Mecanico & SSD", id: "29" }
      ]
    },
    {
      name: "Procesador",
      id: "pa_procesador",
      img: "/wp-content/uploads/2020/02/Procesador.png",
      terms: [
        { name: "Amd", id: "86" },
        { name: "AMD A12", id: "333" },
        { name: "AMD A6", id: "314" },
        { name: "AMD A9", id: "327" },
        { name: "Amd Ryzen", id: "130" },
        { name: "Intel Atom x5-Z8550", id: "157" },
        { name: "Intel Celeron", id: "391" },
        { name: "Intel Core i3", id: "166" },
        { name: "Intel Core i5", id: "26" },
        { name: "Intel Core i7", id: "52" },
        { name: "Intel Pentium", id: "245" }
      ]
    },
    {
      name: "Gráficos",
      id: "pa_placa-de-video",
      img: "/wp-content/uploads/2020/02/Placadevideo.png",
      terms: [
        { name: "Dedicada AMD", id: "264" },
        { name: "Dedicada Ati", id: "203" },
        { name: "Dedicada Nvidia", id: "114" },
        { name: "Integrada Intel o AMD", id: "34" }
      ]
    }
  ];

  let currentAttribute;

  const dispatch = createEventDispatcher();

  const toggleAttribute = e => {
    const targetID = e.currentTarget.dataset.id;
    attributes = attributes.map(attribute =>
      attribute.id != targetID
        ? { ...attribute, open: false }
        : { ...attribute, open: !attribute.open }
    );
    currentAttribute = attributes.find(attribute => attribute.id === targetID);
  };

  const toggleTerm = e => {
    attributes = attributes;
    dispatch("change", attributes);
  };
</script>

<style>
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .attributes {
    display: flex;
    margin-bottom: 1rem;
  }

  .attribute {
    flex: 1;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.7rem;
    position: relative;
    cursor: pointer;
  }

  @media screen and (min-width:1024px) {
    .attributes {
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 2rem;
      padding: 0 1rem;
    }
    .attribute {
      flex: initial;
      margin-right: 3rem;
    }
  }

  @media screen and (min-width:1440px) {
    .attribute {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size:1.25rem;
    }
    .attribute img {
      width: 5rem;
    }
  }

  .attribute img {
    width: 3rem;
    filter: grayscale(1);
    transition: 0.25s ease-out;
  }
  .attribute.modified:before {
    content: '';
    position: absolute;
    bottom: 50%;
    right: 20%;
    width: .5rem;
    background: red;
    z-index: 1;
    border-radius: 50%;
    height: .5rem;
  }

  @media screen and (min-width:768px) {
    .attribute.modified:before {
      right: auto;
      left: 0;
    }
  }

  .attribute img.active {
    filter: grayscale(0);
  }
  .terms {
    padding: 1rem;
    line-height: 2rem;
  }

  @media screen and (min-width:768px) {
    .terms {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      font-size: 1.25rem;
    }
    .terms .term {
      margin: 0 .75rem;
    }
  }

  @media screen and (min-width:1024px) {
    .terms {
      justify-content: flex-start;
    }
  }
</style>

<ul class="attributes">
  {#each attributes as attribute}
    <li class="attribute" data-id={attribute.id} on:click={toggleAttribute} class:modified={attribute.terms.find(term=>term.checked)}>
      <section class="info">
        <img src={attribute.img} alt class:active={attribute.open} />
        {attribute.name}
      </section>
    </li>
  {/each}
</ul>

{#if currentAttribute && currentAttribute.open}
  <ul class="terms" transition:fly={{ y: 50, duration: 250 }}>
    {#each currentAttribute.terms as term}
      <li class="term">
        <label data-id={term.id}>
          <input
            type="checkbox"
            bind:checked={term.checked}
            on:change={toggleTerm} />
          {term.name}
        </label>
      </li>
    {/each}
  </ul>
{/if}
